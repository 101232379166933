




































































































































































































import { Component, Vue } from 'vue-property-decorator';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import LinkedEmergencyFundCardViewModel from '@/vue-app/view-models/components/goals-dashboard/linked-emergency-fund-card-view-model';
import DepositAccountInfo
  from '@/vue-app/components/kuspit-dashboard/deposits/DepositsAccountInfo.vue';

@Component({
  name: 'LinkedEmergencyFundCard',
  components: {
    LoadingDataCard,
    DetailsEmergencyFundModal: () => import('./details-goals/DetailsEmergencyFundModal.vue'),
    EditEmergencyFundGoalPlanDialog: () => import('./edit-plan-goals/edit-emergency-fund-goal-plan/EditEmergencyFundGoalPlanDialog.vue'),
    GoalTrackingTag: () => import('@/vue-app/components/goals-dashboard/GoalTrackingTag.vue'),
    DepositAccountInfo,
  },
})
export default class LinkedEmergencyFundCard extends Vue {
  linked_emergency_fund_view_model = Vue.observable(new LinkedEmergencyFundCardViewModel());

  created() {
    this.linked_emergency_fund_view_model.initialize();
  }
}
